import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@pages/splash-screen/splash-screen.component')
        .then((m) => m.SplashScreenComponent)
  },
  {
    path: 'select-layout',
    loadComponent: () =>
      import('@pages/select-layout/select-layout.component')
        .then((m) => m.SelectLayoutComponent)
  },
  {
    path: 'postcard-editor',
    loadComponent: () =>
      import('@pages/postcard-editor/postcard-editor.component')
        .then((m) => m.PostcardEditorComponent)
  },
  {
    path: 'postcard-filter',
    loadComponent: () =>
      import('@pages/postcard-filter/postcard-filter.component')
        .then((m) => m.PostcardFilterComponent)
  },
  {
    path: 'personal-details/:postcardId',
    loadComponent: () =>
      import('@pages/personal-details/personal-details.component')
        .then((m) => m.PersonalDetailsComponent)
  },
  {
    path: 'postcard-download',
    loadComponent: () =>
      import('@pages/postcard-download/postcard-download.component')
        .then((m) => m.PostcardDownloadComponent)
  }
];
