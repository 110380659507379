import { Component, DestroyRef, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { PreviewComponent } from '@components/preview/preview.component';
import { IDLE_VIDEO_URL } from '@constants/app.constants';
import { DcDirective } from '@directives/dc.directive';
import { DialogService } from '@services/dialog.service';
import { IdleTimeoutService } from '@services/idle-timeout.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DcDirective],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  @ViewChild(DcDirective, { static: true }) dcContainer!: DcDirective;
  private destroyRef = inject(DestroyRef);
  showPopup = false;
  isShowVideo = false;
  readonly videoUrl = IDLE_VIDEO_URL;

  @HostListener('document:mousemove')
  @HostListener('document:keydown')
  handleUserActivity() {
    this.idleTimeoutService.userActivity();
  }

  constructor(
    private dialogService: DialogService,
    private idleTimeoutService: IdleTimeoutService
  ) { }
  ngOnInit(): void {
    this.dialogService.showPopup$.subscribe((res) => (this.showPopup = res));
    this.idleTimeoutService.videoVisibility$.subscribe((res) => this.isShowVideo = res);
    this.dialogService.dialog$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.dialogService.close();
        this.dialogService.open(res.component, {
          dcContainer: this.dcContainer,
          data: res.data
        });
      });
  }

  openDialog() {
    this.dialogService.dialog$.next({
      component: PreviewComponent,
      data: {
        exhibitionId: 5454564
      }
    });
  }

}

