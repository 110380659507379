<div class="bg-black bg-[url('assets/images/bg_pattern.svg')] max-w-full overflow-hidden">
  @if (isShowVideo) {
  <div class="fixed left-0 top-0 w-full z-[999]">
    <video #video loading="eager" fetchpriority="high" class="object-contain w-full h-[100svh] bg-white" width="1920"
      height="950" [muted]="'muted'" preload="metadata" id="vid" [disablePictureInPicture]="true" playsinline loop
      autoplay>
      <source [src]="videoUrl" type="video/mp4">
    </video>
  </div>
  }@else {
  <router-outlet></router-outlet>
  }
</div>
<ng-template appDc></ng-template>