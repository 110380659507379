import { Injectable } from '@angular/core';
import { INACTIVITY_TIMEOUT } from '@constants/app.constants';
import { BehaviorSubject, Subject, takeUntil, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  private inactivityTimer$ = new Subject();
  videoVisibility$ = new BehaviorSubject(false);

  constructor() {
    this.resetTimer();
  }

  resetTimer() {
    this.inactivityTimer$.next({});
    this.videoVisibility$.next(false);
    timer(INACTIVITY_TIMEOUT).pipe(takeUntil(this.inactivityTimer$))
      .subscribe(() => {
        this.videoVisibility$.next(true);
      });
  }

  userActivity() {
    this.resetTimer();
  }

}
