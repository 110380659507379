import { HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment/environment';
import { catchError } from 'rxjs';

export const HttpTokenInterceptor: HttpInterceptorFn = (request, next) => {

  // if (request.url.includes('i18n') || request.url.includes('assets')) {
  const requestUrl = `${environment.hostName}${request.url}`;
  request = request.clone({
    url: requestUrl,
  });
  // }
  return next(request);
};

export const HttpErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const router = inject(Router);

  return next(request).pipe(catchError((error: HttpErrorResponse) => {
    if (error.status === HttpStatusCode.Unauthorized) {
      router.navigate(['/']);
    }
    const err = new HttpErrorResponse({
      error: error.error,
      statusText: error.message,
      status: error.status
    });
    throw err;
  }));
};